<script>
/**
 * Error-404 component
 */
export default {
  page: {
    title: 'Error-404',
  },
  data() {
    return {}
  },
}
</script>

<template>
  <div class="row justify-content-center">
    <div class="col-md-8 col-lg-6 col-xl-5">
      <div class="card">
        <div class="card-body p-4">
          <div class="error-ghost text-center">
            <img src="@/assets/images/error.svg" width="200" alt="error-image" />
          </div>

          <div class="text-center">
            <h3 class="mt-4 text-uppercase font-weight-bold">
              Pagina niet gevonden!
            </h3>
            <p class="text-muted mb-0 mt-3" style="line-height: 20px">
              Maak a.u.b. gebruik van de door ons verstuurde e-mail. U wordt automatisch doorgestuurd naar de juiste order, alwaar u uw bestanden aanlevert.
            </p>

            <router-link to="/" class="btn btn-primary mt-3"><i class="mdi mdi-reply mr-1"></i> Return Home</router-link>
          </div>
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
  <!-- end row -->
</template>
